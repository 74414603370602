.container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  height: auto; /* Remove fixed height */
  background-color: #222222;
}

.paragraphContainer {
  max-width: 800px;
  padding: 20px;
  background-color: #333;
  color: #fff;
  font-family: 'Arial', sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Add margin at the bottom */
}

.paragraph {
  font-size: 16px;
  line-height: 1.6;
}

.highlight {
  font-weight: bold;
  color: #4ba3c0;
}

@media (max-width: 600px) {
  .paragraphContainer {
    max-width: 100%; /* Adjust max-width for smaller screens */
  }
}