.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .optionContainer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
  
  .optionContainer h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .optionContainer p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .downloadButton {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    text-decoration: none;
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .downloadButton:hover {
    transform: scale(1.05); /* Make it slightly bigger on hover */
    background-color: #0069d9; /* Lighter background color */
    box-shadow: 0 0 10px rgba(24, 26, 27, 0.5); /* Lighter box shadow */
  }
  
  .storeButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .storeButton {
    display: inline-block;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    margin: 0 10px;
    border: none;
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .storeButton:hover {
    transform: scale(1.05); /* Make it slightly bigger on hover */
    background-color: #0069d9; /* Lighter background color */
    box-shadow: 0 0 10px rgba(24, 26, 27, 0.5); /* Lighter box shadow */
  }