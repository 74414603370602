/* downloadStyle.module.css */

/* Container for centering the form on the page */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #333; /* Dark background */
    font-family: Arial, sans-serif;
    color: #fff; /* White text */
  }
  
  /* Form container for a nice card look */
  .formContainer {
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Style for the input field */
  .inputField {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #222; /* Dark input background */
    color: #fff; /* White text inside the input */
  }
  
  /* Style for the login/download button */
  .downloadButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .downloadButton:hover {
    background-color: #0056b3;
  }
  