.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
    height: auto;
  }
  
  .profile {
    background-color: #333;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .title {
    font-size: 24px;
    margin: 10px 0;
    color: #fff;
  }
  
  .name {
    font-size: 20px;
    margin: 10px 0;
    color: #fff;
  }
  
  .contact {
    margin-top: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contact-icon {
    font-size: 20px;
    margin-right: 10px;
    color: #fff;
  }
  
  .contact-link {
    color: #fff;
    text-decoration: none;
  }

  