body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #222222; /* Slightly lighter background color */
  color: #fff; /* Text color */
}

.title {
  margin: 0;
  font-size: 1.5em; /* Adjust title font size */
  text-align: center;
  color: white;
  flex-grow: 1;
}

.paragraphContainer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.subtitle {
  margin-top: 0;
  font-size: 1.2em; /* Adjust subtitle font size */
  color: #ccc; /* Lighter color for subtitles */
}

.paragraph {
  color: #ddd; /* Lighter text color */
  line-height: 1.5;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 8px;
  padding: 1em;
}

.paragraph:hover {
  color: #fff;
  background-color: #333; /* Dark background color on hover */
}

.footer {
  text-align: center;
  color: #ff4d4d; /* Red color for the text */
  margin-top: 20px;
  font-size: 1.2em; /* Adjust footer font size */
  padding: 10px;
  background-color: #1a1a1a; /* Dark background color */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2); /* Add box shadow */
}